import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import Utilisateurs from "./../../../nav/Admin/Utilisateurs";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user, users }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Utilisateurs users={users} />;
  } else {
    return <Error403 />;
  }
};

const UtilisateursPage = () => {
  const [user, setUser] = useState(undefined);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUser(setUser);
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((docs) => {
        let idx = 0;
        let newUsers = [];

        while (idx < docs.docs.length) {
          newUsers.push({
            id: docs.docs[idx].id,
            firstName:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .firstName.stringValue,
            lastName:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .lastName.stringValue,
            gender:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .gender.stringValue,
            birthDate:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .birthDate.integerValue,
            city: docs.docs[idx]._delegate._document.data.value.mapValue.fields
              .city.stringValue,
            postalCode:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .postalCode.stringValue,
            phoneNumber:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .phoneNumber.stringValue,
            subscription: {
              type: docs.docs[idx]._delegate._document.data.value.mapValue
                .fields.subscription.mapValue.fields.type.stringValue,
              start:
                docs.docs[idx]._delegate._document.data.value.mapValue.fields
                  .subscription.mapValue.fields.start.integerValue,
              end: docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .subscription.mapValue.fields.end.integerValue,
            },
            email:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .email.stringValue,
            password:
              docs.docs[idx]._delegate._document.data.value.mapValue.fields
                .password.stringValue,
          });
          idx++;
        }
        setUsers(newUsers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Utilisateurs</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici les utilisateurs de WYNIT."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,utilisateurs,liste"
        />
        <link rel="canonical" href="https://wynit.com/admin/utilisateurs" />
      </Helmet>
      <HandleUserStates user={user} users={users} />
    </Layout>
  );
};

export default UtilisateursPage;
