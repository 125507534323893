import React, { useState } from "react"
import {
  Wrapper,
  CSVWrapper,
  Spacer,
  CSVButtonWrapper,
  TableWrapper,

  /* HEADER */
  TitleInfos,
  TitleInfo,

  /* USERS */
  UsersInfos,

  /* USER */
  UserInfos,
  UserInfo,

  /**/
  LineWrapper,
  LineWrapper2,
} from "./Utilisateurs.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Popup from "./../../../components/Popup"
import Button from "./../../../components/Button"
import Edit from "./../../../res/icons/edit.png"
import Trash from "./../../../res/icons/trash.png"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"
import { CSVLink } from "react-csv"
const isBrowser = typeof window !== "undefined"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const getFormatedDate = birthDate => {
  return Intl.DateTimeFormat("fr-FR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(birthDate / 1000)
}

const getTypeOfSubscription = subscription => {
  if (subscription.type === "admin") {
    return "Admin"
  } else if (subscription.type === "monthly") {
    return "Mensuel"
  } else if (subscription.type === "yearly") {
    return "Annuel"
  }
  return "Freemium"
}

const getTransformedPassword = password => {
  let idx = 0
  let result = ""

  while (idx < password.length) {
    result += "*"
    idx++
  }
  return result
}

const HeaderUsersTable = () => {
  return (
    <div className={TitleInfos}>
      <p className={TitleInfo}>Prénom</p>
      <p className={TitleInfo}>Nom</p>
      <p className={TitleInfo}>Genre</p>
      <p className={TitleInfo}>Date de naissance</p>
      <p className={TitleInfo}>Ville</p>
      <p className={TitleInfo}>Code postal</p>
      <p className={TitleInfo}>Numéro de téléphone</p>
      <p className={TitleInfo}>Abonnement</p>
      <p className={TitleInfo}>Email</p>
      <p className={TitleInfo}>Mot de passe</p>
      <p className={TitleInfo}>Offres utilisées</p>
      <div style={{ width: "200px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}></div>
        <p>Modifier</p>
      </div>
      <div style={{ width: "200px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}></div>
        <p>Supprimer</p>
      </div>
    </div>
  )
}

const UserInfosInTable = ({ user, trashIsClicked }) => {
  const editIsClicked = () => {
    if (isBrowser) {
      window.location.href =
        "/admin/utilisateurs/modifier-abonnement?id=" + user.id
    }
  }

  return (
    <div className={UserInfos}>
      <p className={UserInfo}>{user.firstName}</p>
      <p className={UserInfo}>{user.lastName}</p>
      <p className={UserInfo}>{user.gender}</p>
      <p className={UserInfo}>{getFormatedDate(user.birthDate)}</p>
      <p className={UserInfo}>{user.city}</p>
      <p className={UserInfo}>{user.postalCode}</p>
      <p className={UserInfo}>{user.phoneNumber}</p>
      <div
        className={UserInfo}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <p>{getTypeOfSubscription(user.subscription)}</p>
        <div style={{ paddingLeft: "10px" }}></div>
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={editIsClicked}
        >
          <img alt="edit" src={Edit} width="15px" height="15px" />
        </button>
      </div>
      <p className={UserInfo}>{user.email}</p>
      <p className={UserInfo}>{getTransformedPassword(user.password)}</p>
      <p className={UserInfo}>
        <a href={"/admin/utilisateurs/offres-utilisees?id=" + user.id}>Voir</a>
      </p>
      <div style={{ width: "200px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}></div>
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={editIsClicked}
        >
          <img alt="edit" src={Edit} width="15px" height="15px" />
        </button>
      </div>
      <div style={{ width: "200px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}></div>
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={trashIsClicked}
        >
          <img alt="trash" src={Trash} width="15px" height="15px" />
        </button>
      </div>
    </div>
  )
}

const Utilisateurs = ({ users }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [trashPopupIsOpened, setTrashPopupIsOpened] = useState(false)
  const [userTrashSelected, setUserTrashSelected] = useState(0)
  const date = new Date()

  const headersCSVFile = [
    { label: "Index", key: "index" },
    { label: "Prénom", key: "firstName" },
    { label: "Nom", key: "lastName" },
    { label: "Genre", key: "gender" },
    { label: "Numéro de téléphone", key: "phoneNumber" },
    { label: "Date de naissance", key: "birthDate" },
    { label: "Email", key: "email" },
    { label: "Code postal", key: "postalCode" },
    { label: "Ville", key: "city" },
    { label: "Abonnement", key: "subscription" },
  ]

  const toggleTrashPopup = idx => {
    setUserTrashSelected(idx)
    setTrashPopupIsOpened(!trashPopupIsOpened)
  }

  const handleDeleteUser = () => {
    toggleTrashPopup(userTrashSelected)
    firebase
      .firestore()
      .collection("users")
      .doc(users[userTrashSelected].id)
      .delete()
      .then(() => {
        setSuccessSnackbarIsOpened(true)
      })
      .catch(error => {
        //console.log("error when deleting: ", error)
      })
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const getData = () => {
    const data = []

    users.map((user, i = 0) =>
      data.push({
        index: ++i,
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        phoneNumber: user.phoneNumber,
        birthDate: getFormatedDate(user.birthDate),
        email: user.email,
        postalCode: user.postalCode,
        city: user.city,
        subscription: getTypeOfSubscription(user.subscription),
      })
    )
    return data
  }

  const getFilename = () => {
    return (
      "wynit_utilisateurs_" +
      date.getDate() +
      "_" +
      (date.getMonth() + 1 < 10 ? "0" : "") +
      (date.getMonth() + 1) +
      "_" +
      date.getFullYear() +
      ".csv"
    )
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Utilisateurs</h1>
      <div className={CSVWrapper}>
        <div className={Spacer}></div>
        <div className={CSVButtonWrapper}>
          <CSVLink
            data={getData()}
            headers={headersCSVFile}
            filename={getFilename()}
            target="_blank"
          >
            <Button alt="">Exporter en CSV</Button>
          </CSVLink>
        </div>
      </div>
      <div className={TableWrapper}>
        <HeaderUsersTable />
        <div className={LineWrapper}></div>
        <div className={UsersInfos}>
          {users.map((user, idx = 0) => (
            <>
              <UserInfosInTable
                user={user}
                trashIsClicked={() => toggleTrashPopup(idx++)}
              />
              <div className={LineWrapper2}></div>
            </>
          ))}
        </div>
      </div>
      {trashPopupIsOpened && (
        <Popup
          title={"Attention !"}
          onClose={() => toggleTrashPopup(userTrashSelected)}
          question={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
          onCancel={() => toggleTrashPopup(userTrashSelected)}
          onOk={() => handleDeleteUser()}
        />
      )}
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien supprimé cet utilisateur !
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Utilisateurs
